<template>
  <div class="text-center">
    <v-dialog
      v-model="isShowingDialogData"
      :content-class="`tw-text-earl-1000 ${contentClass}`"
      class="chd-modal"
      :fullscreen="$vuetify.display.smAndDown"
      :max-width="maxWidth"
      :persistent="persistent"
      scrollable
      @click:outside="$emit('close-dialog')"
      @keydown.esc="$emit('close-dialog')"
    >
      <v-card variant="elevated">
        <v-card-title class="chd-modal-title">
          <div class="info-modal-title">
            <slot>
              <!-- Can be used to pass an icon to prepend to title or a custom title HTML-->
            </slot>
          </div>
          <span
            v-if="title"
            class="tw-text-2xl tw-font-bold"
            >{{ title }}</span
          >
          <v-spacer />
          <v-btn
            v-if="showTopRightCloseButton"
            icon="mdi-close-circle-outline"
            aria-label="close"
            class="tw--mt-1"
            @click="$emit('close-dialog')"
          />
        </v-card-title>

        <v-card-text class="chd-modal-text">
          <div class="tw-font-medium">
            {{ text }}
          </div>
          <slot name="content">
            <!-- Body -->
          </slot>
        </v-card-text>

        <section>
          <v-divider />
          <v-spacer />
        </section>

        <slot name="preButtonAction">
          <!-- preButtonAction -->
        </slot>

        <v-card-actions
          v-if="showActionButtons"
          class="chd-modal-actions"
        >
          <v-spacer />
          <SpiceworksButton
            v-if="showCloseButton"
            :text="closeButtonText"
            @click="$emit('close-dialog')"
          />
          <slot name="buttonActions">
            <!-- Custom Buttons -->
          </slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SpiceworksButton from '@/src/common/components/SpiceworksButton.vue';

/* eslint-disable vue/component-api-style */
export default {
  components: { SpiceworksButton },

  props: {
    title: {
      type: String,
      default: '',
    },

    text: {
      type: String,
      default: '',
    },

    isShowingDialog: {
      type: Boolean,
      default: false,
    },

    maxWidth: {
      type: String,
      default: '600px',
    },

    showActionButtons: {
      type: Boolean,
      default: false,
    },

    showCloseButton: {
      type: Boolean,
      default: false,
    },

    closeButtonText: {
      type: String,
      default: 'Close',
    },

    contentClass: {
      type: String,
      default: '',
    },

    showTopRightCloseButton: {
      type: Boolean,
      default: true,
    },

    persistent: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['close-dialog', 'update:isShowingDialog'],

  data() {
    return {
      isShowingDialogData: this.isShowingDialog,
    };
  },

  computed: {
    hasButtonActionSlot() {
      // Make the extra close button to be secondary
      return !!this.$slots.buttonActions == true;
    },
    buttonColor() {
      // Make the extra close button to be secondary
      return this.hasButtonActionSlot ? '' : 'dark:tw-bg-dusk-200 dark:tw-text-earl-900';
    },
  },
  watch: {
    isShowingDialog(newValue) {
      this.isShowingDialogData = newValue;
    },
    isShowingDialogData(newValue) {
      this.$emit('update:isShowingDialog', newValue);
      if (newValue === false) {
        this.$emit('close-dialog');
      }
    },
  },
};
</script>

<style scoped>
/* TODO: This isn't working yet */
.info-modal-title {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
}
</style>
